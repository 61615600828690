root {
  font-family: 'Hiragino Maru Gothic ProN W4', 'MS Gothic', 'Yu Gothic',
    'YuGothic', 'Hiragino Sans', serif !important;
}

body {
  margin: 0;
  font-family: 'Hiragino Maru Gothic ProN W4', 'MS Gothic', 'Yu Gothic',
    'YuGothic', 'Hiragino Sans', serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Microsoft YaHei',
    'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol'; */
  /* font-family: 'Helvetica Neue', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans',
    Meiryo, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  height: 5px;
  margin-top: -12px;
}

.duration {
  bottom: 0;
  right: 0;
  position: absolute;
  margin-bottom: 12px;
  margin-right: 8px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.7;
  padding: 4px 4px;
  border-radius: 3px;
  letter-spacing: 0.5px;
  font-size: 18px;
  font-weight: 500;
  line-height: 14px;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  /* min-height: 200px; */
  /* text-align: center; */
  padding: 20px;
}

.steps-action {
  margin-top: 24px;
}

.main360-ok {
  color: rgb(0, 12, 121);
  font-size: 16px;
  background-color: rgba(223, 238, 248, 0.8);
}
.main360 {
  color: rgb(0, 12, 121);
  font-size: 16px;
  background-color: rgba(188, 209, 215, 0.8);
}

.sub360 {
  color: rgb(79, 1, 143);
  font-size: 14px;
  background-color: rgba(206, 207, 175, 0.8);
}
.sub360-ok {
  color: rgb(79, 1, 143);
  font-size: 14px;
  background-color: rgba(254, 255, 213, 0.8);
}
